import {useState} from "react";
import {api} from "../api/api";
import {useCustomToast} from "./useCustomToast";

export const useDownloadFile = ({value = [] as any}) => {

    const toast = useCustomToast({})
    const [isDownloading, setIsDownloading] = useState(false)

    const downloadFile = async (path: string) => {

        setIsDownloading(true)
        let fileName: any = []

        try {

            const {headers, data} = await api.post(path, value,{
                responseType: 'blob',
                timeout: 10000,
            })

            const content = headers['content-disposition']

            fileName = content ? content.split("=").at(1) : ''

            // Create blob link to download
            const link = document.createElement('a')
            link.href = URL.createObjectURL(new Blob([data]))

            link.download = fileName

            // Start download
            link.click()

            // Clean up and remove the link
            link.parentElement?.removeChild(link)

        } catch (error: any) {
            setIsDownloading(false)

            if (error.response &&
                error.response.status === 403) {
                if (!toast.isActive("custom-toast")) {
                    toast({
                        title: `Sesión cerrada por inactividad`,
                        status: 'info'
                    })
                }
                return
            }

            console.error("cannot download file")

            if (!toast.isActive("custom-toast")) {
                toast({title: `No se pudo descargar el archivo!`, status: 'error'})
            }
            return
        }

        setIsDownloading(false)
    }

    return {
        downloadFile,
        isDownloading
    }
}