import {Skeleton, Stack} from "@chakra-ui/react";


export const TableSkeleton = ({isLoading}: any) => {
    return (
        <>
            {isLoading ?
                <Stack mt={2}>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                    <Skeleton height='30px' borderRadius={5}/>
                </Stack>
                : []
            }
        </>
    )
}