import {useToast} from "@chakra-ui/react";

export const useCustomToast = ({title, status}: any) => {
    const toast = useToast({
        title,
        status,
        id: 'custom-toast',
        duration: 4000,
        isClosable: true,
        position: 'bottom-right',
    })

    return (toast)

}